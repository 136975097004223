<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getDict } from './api'

export default {
  data() {
    return {
      userinfo: null
    };
  },
  mounted() {
    //this.fetchWechatCode()
    this.fetchCustomeUrl()
    let userinfo = JSON.parse(localStorage.getItem('userinfo'))
    let token = userinfo?.token
    //let token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50Ijoi6aOO6ZuoXzYiLCJ1aWQiOjE4NSwibGFuZyI6IiIsImNsaWVudElwIjoiIiwicm9sZUlkIjowLCJyb2xlTmFtZSI6IiIsImxvZ2luVGltZSI6IjIwMjQtMDItMDUgMDk6Mjk6MjIiLCJwaWQiOjAsInBhcmVudFBhdGgiOiIiLCJpc3MiOiJzdHJhbmdlciIsInN1YiI6InNvbWVib2R5IiwiZXhwIjoxNzEwNjk2NTYyLCJuYmYiOjE3MDcxMDAxNjIsImlhdCI6MTcwNzEwMDE2Mn0.jd-crXX_LWU3Wh-7FzoifcV0VRczpXOfkL8jpWqxVmo'
    if (token) {
      this.fetchBalancePlusData()
      this.fetchUserInfo()
      this.$router.push('/home')
    } else {
      if(!sessionStorage.getItem('isJump'))  this.jumpLogin()
    }
    
  },
  methods: {
    ...mapActions(['fetchBalancePlusData', 'fetchUserInfo','fetchWechatCode','fetchCustomeUrl']),
    async jumpLogin() {
      let res = await getDict({
        k: 'wechat_login_url'
      })
      if (res.code === 0) {
        window.location.href = res.data
        sessionStorage.setItem('isJump', true)
      } else {
        this.$toast(res.msg)
      }
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;

}

@import url("./assets/css/common.css");
</style>

<template>
    <div class="tabbar-wrap">
        <div class="list">
            <div class="item" v-for="item in list" :key="item.id" @click="switchTab(item)">
                <img :src="actIndex === item.id ? item.icon_act : item.icon" alt="">
                <div :class="{'text-act': actIndex === item.id}">{{ item.name }}</div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        actIndex: {
            type: Number,
            default: 0,
        }
    },
    watch: {
        '$i18n.locale': 'updateI18n',
    },
    data() {
        return {
            list: [
                { id:0, name: this.$t("tabbar.lang1"), url: '/home',icon: require('../../assets/icon/index.png'),icon_act: require('../../assets/icon/index_act.png')},
                { id:1, name: this.$t("tabbar.lang2"), url: '/fund',icon: require('../../assets/icon/funds.png'),icon_act: require('../../assets/icon/funds_act.png')},
                { id:2, name: this.$t("tabbar.lang3"), url: '/user',icon: require('../../assets/icon/my.png'),icon_act: require('../../assets/icon/my_act.png')},
            ],

        };
    },
    mounted() {

    },
    methods: {
        switchTab(item) {
            if(this.actIndex === item.id) return;
            this.$router.push(item.url)
        },
        updateI18n() {
            this.list = [
                { id:0, name: this.$t("tabbar.lang1"), url: '/home',icon: require('../../assets/icon/index.png'),icon_act: require('../../assets/icon/index_act.png')},
                { id:1, name: this.$t("tabbar.lang2"), url: '/fund',icon: require('../../assets/icon/funds.png'),icon_act: require('../../assets/icon/funds_act.png')},
                { id:2, name: this.$t("tabbar.lang3"), url: '/user',icon: require('../../assets/icon/my.png'),icon_act: require('../../assets/icon/my_act.png')},
            ]
        }
    },
};
</script>

<style scoped lang="scss">
.tabbar-wrap {
    .list {
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: space-around;
        width: 100%;
        font-size: 12px;
        background-color: #fff;
        box-shadow: 0 -1px  1px rgba(100, 100, 100, .2);
        z-index: 999;
        .item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 4px 0;
            font-weight: 600;
            img {
                width: 24px;
                height: 24px;
                margin-top: 2px;
                margin-bottom: 6px;
            }
            .text-act {
                color: #476ddb;
            }
        }
    }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    component: () => import ('../components/Home.vue')
  },
  {
    path: '/login',
    component: () => import ('../components/Login.vue'),
  },
  {
    path: '/fund',
    component: () => import ('../components/Fund.vue')
  },
  {
    path: '/user',
    component: () => import ('../components/User.vue')
  },
  {
    path: '/fundPurchase',
    component: () => import ('../components/FundPurchase.vue')
  },
  {

    path: '/balance',
    component: () => import ('../components/Balance.vue')
  },
  {

    path: '/comment',
    component: () => import ('../components/Comment.vue')
  },
  {

    path: '/deal',
    component: () => import ('../components/Deal.vue')
  },
  {

    path: '/profit',
    component: () => import ('../components/Profit.vue')
  },
  {

    path: '/transfer_out',
    component: () => import ('../components/Transfer_out.vue')
  },
  {

    path: '/transfer_in',
    component: () => import ('../components/Transfer_in.vue')
  },
  {

    path: '/edit_comment',
    component: () => import ('../components/Edit_comment.vue')
  },
  {
    path: '/funDetails',
    component: () => import ('../components/FunDetails.vue')
  },
  {

    path: '/funList1',
    component: () => import ('../components/FunLists/FunList1.vue')
  },
  {

    path: '/funList2',
    component: () => import ('../components/FunLists/FunList2.vue')
  },
  {

    path: '/funList3',
    component: () => import ('../components/FunLists/FunList3.vue')
  },
  {
    
    path: '/funChanges',
    component: () => import ('../components/FunChanges.vue')
  },
  {
    
    path: '/accountChanges',
    component: () => import ('../components/AccountChanges.vue')
  },
  {
    
    path: '/bindbankcard',
    component: () => import ('../components/BindBankCard.vue')
  },
  {
    
    path: '/banksList',
    component: () => import ('../components/BanksList.vue')
  },
  {
    
    path: '/userInfo',
    component: () => import ('../components/userInfo.vue')
  },
  {
    
    path: '/setpwd',
    component: () => import ('../components/SetPwd.vue')
  },
  {
    
    path: '/withdraw',
    component: () => import ('../components/Withdraw.vue')
  },{
    
    path: '/withdrawRecord',
    component: () => import ('../components/WithdrawRecord.vue')
  },{
    
    path: '/daily_income',
    component: () => import ('../components/Daily_income.vue')
  },
  {
    
    path: '/accountdetails',
    component: () => import ('../components/AccountDetails.vue')
  } ,
  {
    path: '/singleFundRecord',
    component: () => import ('../components/SingleFundRecord.vue')
  },
  {
    path: '/singleWithdrawalRecord',
    component: () => import ('../components/SingleWithdrawalRecord.vue')
  },
  {
    path: '/aboutUs',
    component: () => import ('../components/AboutUs.vue')
  },
  {
    path: '/pdf',
    component: () => import ('../components/Pdf.vue')
  },
  {
    path: '/recharge',
    component: () => import ('../components/Recharge.vue')
  },
  {
    path: '/customer',
    component: () => import ('../components/Customer.vue')
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
export default router

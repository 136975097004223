export default {
    common: {
        title: 'Wealth通',
        symbol: '¥',
        currency: '元',
        login: '登录',
        lang1: '刚刚',
        lang2: '分钟前',
        lang3: '小时前',
        lang4: '昨天',
        lang5: '我的资产',
        lang6: '昨日收益',
        lang7: '查看更多',
        lang8: '余额宝',
        lang9: '基金',
        lang10: '自选',
        lang11: '最高7日年化',
        lang12: '低风险',
        lang13: '中风险',
        lang14: '中高风险',
        lang15: '高风险',
        lang16: '近3年涨跌幅',
        lang17: '近1年涨跌幅',
        lang18: '累计收益',
        lang19: '持有中',
        lang20: '取消',
        lang21: '暂时没有数据' ,
        lang22:'确认',
        lang23:'取消',
        lang24:'没有更多了',
        lang25: '语言',
        lang26:'没有绑定卡片',
        lang27:'订单详情',
        lang28:'扫描二维码,加我为好友',
        lang29:'领取成功'
        
    },
    shareCard: {
        lang1: '提示',
        lang2: '确认',
        lang3: '添加晒一晒卡片会移除所有,确定添加吗',
        lang4: '添加图片会移除晒一晒卡片,确定添加吗',
        lang5: '晒一晒',
        lang6: '完成',
    },
    tabbar: {
        lang1: '今日',
        lang2: '基金',
        lang3: '我的'
    },
    login: {
        lang1: '创建新账号',
        lang2: '已有帐号',
        lang3: '联系客服',
        lang4: '未授权登录,请重新授权'
    },
    home: {
        lang1: '新用户优选',
        lang2: '历史无亏损 转出秒到账',
        lang3: '买一笔试试',
        lang4: '稀缺资产开售提醒',
        lang5: '长按关注账号',
        lang6: '腾讯官方',
        lang7: '投资用户超4亿，管理资产超20000亿',
        lang8: '海量基金',
        lang9: '近1000只基金任你选，申购费1折起',
        lang10: '安全保障',
        lang11: '严控风险，兑付0延期，资金0被查',
        lang12: '收益详情'
    },
    balance: {
        lang1: '历史天天正收益，转出最快秒到账',
        lang2: '查看明细',
        lang3: '总金额',
        lang4: '转出',
        lang5: '转入',
        lang6: '讨论区',
        lang7: '"余额宝" 4大特性',
        lang8: '享收益',
        lang9: '用途多',
        lang10: '随时取',
        lang11: '有保障',
        lang12: '交易明细',
        lang13: '收益明细',
        lang14: '转出金额',
        lang15: '可以转出',
        lang16: '请输入金额',
        lang17: '操作成功',
        lang18: '转入金额',
        lang19: '已知悉风险提示并同意服务协议',
        lang20: '请勾选服务协议',
        lang21: '可以转入',
    },
    comment: {
        lang1: '"余额宝讨论区"',
        lang2: '产品详情',
        lang3: '最新',
        lang4: '精华',
        lang5: '发表了帖子',
        lang6: '发表观点',
        lang7: '发表',
        lang8: '发表你的看法',
        lang9: '图片',
        lang10: '表情',
        lang11: '内容不能为空',
        lang12: '发表成功',
    },
    fund:{
        a1:'关于我们',
        a2:'收益',
        a3:'总购买基金',
        a4:'昨日收益',
        a5:'总收益',
        a6:'长牛基金',
        a7:'查看更多',
        a8:'均衡配置多行业，业绩连续跑赢大盘',
        a9:'主动优选',
        a10:'超31万用户认可',
        a11:'连续八年超大盘',
        a12:'AI辅助量化优选',
        a13:'多次精准布局',
        a14:'关注中小盘风格',
        a15:'布局高分红企业',
        a16:'5年涨幅超100%',
        a17:'量化选股',
        a18:'近3年涨跌幅',
        a19:'近1年涨跌幅',
        a20:'低风险',
        a21:'中风险',
        a22:'中高风险',
        a23:'高风险',
        a24:'累计为用户盈利',
        a25:'截至',
        a26:'灵活申赎',
        a27:'专家推荐',
        a28:'追求年收益',
        a29:'稳健水平',
        a30:'大数据加持',
        a31:'无亏损',
        a32:'稳增长',
        a33:'选绩优'
    },
    fundPurchase:{
        b1:'腾讯腾安',
        b2:'买入金额',
        b3:'交易规则',
        b4:'您可以购买基金的额度为:',
        b5:'买入费率',
        b6:'请输入支付密码',
        b7:'密码为 6 位数字',
        b8:'已悉知风险提示并且同意服务协议',
        b9:'购买成功',
        b10:'请输入金额',
        b11:'请勾选同意',
        b12:'买入',
        b13:'1元起购'

    },
    user:{
        c1:'关于我们',
        c2:'关于我们',
        c3:'欢迎开启财富之旅',
        c4:'我的资产',
        c5:'试用金',
        c6:'活期资产',
        c7:'稳健资产',
        c8:'进阶资产',
        c9:'短期灵活',
        c10:'求稳增值',
        c11:'追求收益',
        c12:'工具服务',
        c13:'账变记录',
        c14:'余额记录',
        c15:'基金记录',
        c16:'更多信息',
        c17:'账户安全',
        c18:'我的客服',
        c19:'关于我们',
        c20:'提现',
        c21:'充值',
    },
   
    accountChanges:{
        d1:'关于我们',
        d2:'账变记录'
    },
    funChanges:{
        e1:'基金交易记录',
        e2:'进行中',
        e3:'已过期',
        e4:'剩余时间',
        e5:'天',
        e6:'订单详情',
        e7:'状态：',
        e8:'进行中',
        e9:'已结束',
        e10:'暂停结算',
        e11:'周期',
        e12:'剩余天数',
        e13:'购买数量',
        e14:'累计收益', 
        e15:'购买时间'
    },
    userInfo:{
        f1:'个人信息',
        f2:'男',
        f3:'女',
        f4:"年龄",
        f5:'请选择国家',
        f6:'银行名称',
        f7:'银行卡号',
        f8:"请设置交易密码",
        f9:'确认交易密码',
        f10:'请输入',
        f11:'请再次输入',
        f12:'提交',
        f13:''
    },
    fundDetails:{
        e1:'关于我们',
        e2:'低风险',
        e3:'中风险',
        e4:'中高风险',
        e5:'高风险',
        e6:'元起购',
        e7:'成立涨幅',
        e8:'近1年涨幅',
        e9:'近3年涨幅',
        e10:'立即买入',
        e11:'历史业绩',
        e12:'转入1万元,过去7天的每日收益如下',
    },
    bankCard:{
        a1:'银行卡信息',
        a2:'选择国家',
        a3:'选择银行',
        a4:'银行卡号',
        a5:'请输入银行卡号',
        a6:'持卡人',
        a7:'请输入持卡人姓名',
        a8:'查看已绑定的银行卡',
        a9:'绑定',
        a10:'请输入支付密码',
        a11:'绑定成功',
        a12:'输入有误',
        a13:'我的卡片',
        a14:'你还没有绑定过安全卡',
        a15:'修改银行卡',
        a16:'安全卡',
        a17:'成为安全卡',
        a18:'首次购买理财产品的卡将成为安全卡',
        a19:'安全卡取出',
        a20:'安全卡与理财账户绑定，来自银行的资金可以仅能取出自安全卡',
        a21:'安全有保障',
        a22:'即使手机丢失,资金也仅能取出到你的安全卡',
        a23:'更换安全卡',
        a24:'通过官方审核后，可更换安全卡',
        a25:'',
        a26:''

    },
    accountChanges:{
        a1:'账变',
        a2:'类型',
        a3:'选择类型',
        a4:'订单号',
        a5:'金额',
        a6:'购买前余额',
        a7:'购买后余额',
        a8:'时间',
        a9:'订单号',
        a10:'提现',
        a11:'冻结',
        a12:'余额宝转入',
        a13:'购买理财',
        a14:'扣除',
        a15:'每日登录奖励',
        a16:'支付宝充值',
        a17:'理财到期',
        a18:'余额宝转出',
        a19:'解冻',
        a20:'提现失败'
    },
    setPwd:{
        a1:'设置交易密码',
        a2:'交易密码',
        a3:'请输入6位交易密码',
        a4:'确认交易密码',
        a5:'请再次输入交易密码',
        a6:'请输入6位数交易密码',
        a7:'保存',
        a8:'设置成功',
        a9:'输入有误请重新输入',
    },
    Withdraw:{
        a2:'提现',
        a3:'提现金额',
        a4:'交易密码',
        a5:'输入交易密码',
        a6:'查看提现记录',
        a7:'提现',
        a8:'正在提现',
        a9:'可提现金额'
    },
    WithdrawRecord:{
        e1:'基金交易记录',
        e2:'进行中',
        e3:'记录',
        e4:'提现失败',
        e5:'正在审核',
        e6:'订单详情',
        e7:'状态：',
        e8:'正在审核',
        e9:'已结束',
        e10:'暂停结算',
        e11:'提现地址',
        e12:'银行名',
        e13:'持卡人',
        e14:'币种',
        e15:'创建时间',
        e16:'提现成功',
        e17:'更新时间',
        e18:'金额'
    },
    aboutas:{
        a1:'理财通介绍',
        a2:'理财通是腾讯旗下的上海腾富金融信息服务有限公司，与多家金融机构合作，为用户提供多样化理财服务的平台。其网站备案/许可证号：沪ICP备2021008169号。',
        a3:'在理财通平台，金融机构作为金融产品的提供方，负责金融产品的结构设计和资产运作，为用户提供账户开立、账户登记、产品买入、收益分配、产品取出、份额查询等服务，同时严格按照相关法律法规，以诚实信用、谨慎勤勉的原则管理和运用资产，保障用户的合法权益。',
        a4:'理财通作为服务平台，通过与多家金融机构的系统进行对接，为用户提供金融产品信息查询、交易下单等辅助服务的入口，协助用户在金融机构完成账户开立、买入与取出、信息查询等服务。用户在理财通买入相关金融产品时，需要仔细了解金融产品相关投资风险，充分考虑自身的风险承受能力，依据“买者自负”的原则，独立做出投资决策。'
    },
    recharge: {
        r1: '资金安全保障中',
        r2: '最高获百万资金赔付',
        r3: '实时到账',
        r4: '免手续费',
        r5: '随时提现',
        r6: '充值金额',
        r7: '立即充值',
        r8: '为什么选择我们',
        r9: '多元投资',
        r10: '更高收益',
        r11: '海量导师',
        r12: '0亏损',
    }


}
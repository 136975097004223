// api/index.js
import axios from 'axios';
import baseURL from '../apiConfig.js';
// 创建一个 Axios 实例
const api = axios.create({
  baseURL:baseURL,
  timeout: 20000, // 请求超时时间
  withCredentials: true
});


// 在请求拦截器中设置 token
//测试 token 
api.interceptors.request.use(
  config => {
    let userinfo = JSON.parse(localStorage.getItem('userinfo'))
    let token = userinfo ?.token
  // let token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50IjoidGVzdDk5OSIsInVpZCI6MTg2LCJsYW5nIjoiIiwiY2xpZW50SXAiOiIxMDMuMTcxLjE0NC40MyIsInJvbGVJZCI6MCwicm9sZU5hbWUiOiIiLCJsb2dpblRpbWUiOiIyMDI0LTAxLTI5IDEzOjQzOjA2IiwicGlkIjowLCJwYXJlbnRQYXRoIjoiIiwiaXNzIjoic3RyYW5nZXIiLCJzdWIiOiJzb21lYm9keSIsImV4cCI6MTcxMDEwNjk4NiwibmJmIjoxNzA2NTEwNTg2LCJpYXQiOjE3MDY1MTA1ODZ9.J49LcGeGjnY81RK3iHld-Z32DDyGs5c_aiLf9jYlTwA'
  // let token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50IjoidGVzdDkyMDAiLCJ1aWQiOjE5MCwibGFuZyI6IiIsImNsaWVudElwIjoiMzYuMzcuMTk2Ljg5Iiwicm9sZUlkIjowLCJyb2xlTmFtZSI6IiIsImxvZ2luVGltZSI6IjIwMjQtMDItMDYgMTQ6NDA6NTYiLCJwaWQiOjAsInBhcmVudFBhdGgiOiIiLCJpc3MiOiJzdHJhbmdlciIsInN1YiI6InNvbWVib2R5IiwiZXhwIjoxNzEwODAxNjU2LCJuYmYiOjE3MDcyMDUyNTYsImlhdCI6MTcwNzIwNTI1Nn0.HUsQ_p0hrafJX5jCE7TUeELzqlWAwWYmaE6gZLAxarE'
  // let token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50IjoidGVzdDkyMDEiLCJ1aWQiOjE5MSwibGFuZyI6IiIsImNsaWVudElwIjoiMzYuMzcuMTk2Ljg5Iiwicm9sZUlkIjowLCJyb2xlTmFtZSI6IiIsImxvZ2luVGltZSI6IjIwMjQtMDItMDYgMTQ6NDE6MjAiLCJwaWQiOjAsInBhcmVudFBhdGgiOiIiLCJpc3MiOiJzdHJhbmdlciIsInN1YiI6InNvbWVib2R5IiwiZXhwIjoxNzEwODAxNjgwLCJuYmYiOjE3MDcyMDUyODAsImlhdCI6MTcwNzIwNTI4MH0.veTZUkwG0gRu5KlrVg5XH6kYXzifw1Dlahm5jtBIWvU'  
  // let token='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50IjoiTmkgTml1XzIiLCJ1aWQiOjE5MywibGFuZyI6IiIsImNsaWVudElwIjoiMTAzLjE3MS4xNDQuNDMiLCJyb2xlSWQiOjAsInJvbGVOYW1lIjoiIiwibG9naW5UaW1lIjoiMjAyNC0wMi0wOCAxNDo0NDozNCIsInBpZCI6MCwicGFyZW50UGF0aCI6IiIsImlzcyI6InN0cmFuZ2VyIiwic3ViIjoic29tZWJvZHkiLCJleHAiOjE3MTA5NzQ2NzQsIm5iZiI6MTcwNzM3ODI3NCwiaWF0IjoxNzA3Mzc4Mjc0fQ.w0mISJfqeyAOUoD6vB-4x97cAng5loK7n2XDWgrPSsI'
  let lang = sessionStorage.getItem('UNI_LOCALE') ?  sessionStorage.getItem('UNI_LOCALE') : 'ms'; //navigator.language.split('-')[0]
    config.headers['Lang'] = lang;  
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 设置 Axios 响应拦截器
api.interceptors.response.use(
  response => {
    if (response.data.code === 403 ) {
      let userinfo = JSON.parse(localStorage.getItem('userinfo'))
      window.location.href = userinfo ?. auth_url
      localStorage.clear();
      sessionStorage.removeItem('isJump');
    }
    return response.data;
  },
  error => {
    console.log(error)
    return Promise.reject(error);
  }
);

const get = (url, params) => api.get(url, { params });
const post = (url, data) => api.post(url, data);

export const financial = () => get('api/financial/index2'); //基金首页
export const financialMore = () => get('api/financial/index'); //基金详情
export const financialList = (data) => get('api/financial/index3',data); //基金列表
export const summery = () => get('api/financial/summery');//基金收益 
export const financialUser = () => get('api/financial/user');//查询理财记录 
export const reportDay = () => get('api/financial/reportDay');//每日收益 
export const incomeLog = () => get('api/financial/incomeLog');//查询理财收益记录 
export const financialBuy = (data) => post('api/financial/buy',data);//购买理财 
export const changeLog = (data) => get('api/wallet/changeLog',data);//账变 
export const payPass = (data) => post('api/user/payPass',data);//设置支付密码 
export const financialU = () => get('api/financial/user');//用户理财记录  
export const bankCountry = () => get('api/wallet/bankCountry');//查询国家 
export const banks = (data) => get('api/wallet/banks',data);//查询银行卡 
export const bindBank = (data) => post('api/wallet/bindBank',data);//绑定银行卡 
export const userBanks = () => get('api/wallet/userBanks');//查询用户已绑定的卡 
export const transactionType = () => get('api/wallet/transactionType');// 账变类型 
export const getDict = (data) => get('api/getDict',data);// 字典 
export const withdraw = (data) => post('api/wallet/withdraw',data);// 提现
export const getwithdraw = (data) => get('api/wallet/withdraw',data);// 提现查询 
export const returnRateLogs = (data) => get('api/financial/returnRateLogs',data);// 查询产品历史收益率 
export const getBankcard = (data) => post('api/wallet/getBankcard',data);// 查询银行卡号 
export const getrate = (data) => post('/api/sys/bank/rate',data);// 查询银行卡号 


export const register = (data) => post('/api/wechat/create',data);
export const bind = (data) => post('/api/wechat/bind',data);
export const login = (data) => post('/api/wechat/login',data);

// 余额+ start
export const getBalancePlusData = () => get('/api/balance_plus/data'); // 余额+ 余额

export const getBalanceRecord = (data) => get('/api/balance_plus/record',data); //交易\收益明细

export const transferBalance = (data) => post('/api/balance_plus/transfer',data); //转入转出

export const getCommentRecord = (data) => get('/api/comment/record',data); //评论记录

export const publishComment = (data) => post('/api/comment/publish',data); //发表评论

export const getReportDay = (data) => get('/api/financial/reportDay',data); 
// end
export const getUserInfo = () => get('/api/user/info'); // 余额+ 余额
export const upload = (data) => post('/api/sys/file/upload',data); //上传

export const getWechatCode = (data) => get('/api/sys/banner',data); //微信二维码

export const getBonusList = (data) => post('/api/activity/list',data); //活动列表
export const receiveBonus = (data) => post('/api/activity/give',data); //领取红包
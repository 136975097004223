export default {
    common: {
        title: 'Wealth Management',
        symbol: '$',
        currency: 'dollars',
        login: 'login',
        lang1: 'Tadi',
        lang2: 'minit lalu',
        lang3: 'jam yang lalu',
        lang4: 'Semalam',
        lang5: 'Aset Saya',
        lang6: 'Pendapatan Semalam',
        lang7: 'Lihat Lagi',
        lang8: "Yu'E Bao",
        lang9: 'Dana',
        lang10: 'Kegemaran',
        lang11: 'Pulangan Tahunan 7 Hari Tertinggi',
        lang12: 'Risiko Rendah',
        lang13: 'Risiko Sederhana Rendah',
        lang14: 'Risiko Sederhana Tinggi',
        lang15: 'Berisiko Tinggi',
        lang16: ' 3 Tahun',
        lang17: '1 Tahun',
        lang18: 'Pendapatan Terkumpul',
        lang19: 'Memegang',
        lang20: 'Batal',
        lang21: 'Tiada data tersedia pada masa ini',
        lang22:'sahkan',
        lang23:'batal',
        lang24:'Tiada lagi',
        lang25: 'Bahasa',
        lang26:'Tiada kad terikat',
        lang27:'butiran pesanan',
        lang28:'Imbas kod QR dan tambah saya sebagai rakan',
        lang29:'Berjaya diterima'
    },
    shareCard: {
        lang1: 'Segera',
        lang2: 'Sahkan',
        lang3: 'Menambah kad "Kongsi" akan mengalih keluar semua kad sedia ada. Adakah anda pasti mahu menambahnya?',
        lang4: 'Menambah imej akan mengalih keluar kad "Kongsi". Adakah anda pasti mahu menambahnya?',
        lang5: 'Kongsi',
        lang6: 'Selesai',
    },
    
    tabbar: {
        lang1: 'Hari ini',
        lang2: 'Dana',
        lang3: 'Milik saya',
    },
    
    login: {
        lang1: 'Buat Akaun Baharu',
        lang2: 'Sudah Mempunyai Akaun',
        lang3: 'Hubungi Khidmat Pelanggan',
        lang4: 'Log masuk tidak dibenarkan, sila mendapatkan kebenaran semula'
    },
    
    home: {
        lang1: 'Istimewa Pengguna Baharu',
        lang2: 'Tiada Kehilangan Sejarah, Pengeluaran Segera',
        lang3: 'Cuba Beli Satu',
        lang4: 'Peringatan Jualan Aset Terhad',
        lang5: 'Tekan lama untuk Ikut Akaun Kewangan',
        lang6: 'Tencent Official',
        lang7: 'Lebih 400 Juta Pengguna Pelaburan, Mengurus Aset Lebih 2 Trilion',
        lang8: 'Dana Besar',
        lang9: 'Hampir 1000 Dana untuk Dipilih, Bermula daripada Potongan 10%',
        lang10: 'Jaminan Keselamatan',
        lang11: 'Kawalan Risiko yang Ketat, Tiada Kelewatan dalam Penebusan, Sifar Pembekuan Dana',
        lang12: 'Butiran hasil'
    },
    
    balance: {
        lang1: 'Pendapatan Positif Harian Bersejarah, Pengeluaran Terpantas',
        lang2: 'Lihat Butiran',
        lang3: 'Jumlah Amaun',
        lang4: 'Tarik diri',
        lang5: 'Deposit',
        lang6: 'Kawasan Perbincangan',
        lang7: "4 Ciri Utama 'Yu'E Bao'",
        lang8: 'Nikmati Pendapatan',
        lang9: 'Penggunaan Serbaguna',
        lang10: 'Tarik Balik Bila-bila Masa',
        lang11: 'Terjamin',
        lang12: 'Butiran Transaksi',
        lang13: 'Butiran Pendapatan',
        lang14: 'Amaun Pengeluaran',
        lang15: 'Boleh Ditarik Balik',
        lang16: 'Sila Masukkan Amaun',
        lang17: 'Operasi Berjaya',
        lang18: 'Amaun Deposit',
        lang19: 'Saya telah membaca dan bersetuju menerima amaran risiko dan perjanjian perkhidmatan',
        lang20: 'Sila semak perjanjian perkhidmatan',
        lang21: 'Boleh dimasukkan',
    },
    comment: {
        lang1: "'Yu'E Bao' Kawasan Perbincangan",
        lang2: 'Butiran Produk',
        lang3: 'Terbaharu',
        lang4: 'Penting',
        lang5: 'Menyiarkan Siaran',
        lang6: 'Kongsi Pendapat Anda',
        lang7: 'Siaran',
        lang8: 'Kongsi Fikiran Anda',
        lang9: 'Imej',
        lang10: 'Emoji',
        lang11: 'Kandungan tidak boleh kosong',
        lang12: 'Siaran Berjaya',
    },
    fund:{
        a1:'Pengurusan Kewangan',
        a2: 'Keuntungan',
        a3:'Jumlah',
        a4:'Semalam',
        a5:'Jumlah pendapatan',
        a6:'Long Bull Fund',
        a7:'Lihat lagi',
        a8: 'prestasi mengatasi pasaran',
        a9: 'stok pertumbuhan',
        a10: 'Diluluskan oleh lebih 310,000 pengguna',
        a11: 'Pasaran super selama lapan tahun berturut-turut',
        a12:'Pengoptimuman kuantifikasi berbantukan AI',
        a13: 'Berbilang susun atur tepat',
        a14:'Fokus pada gaya topi kecil dan sederhana',
        a15: 'Sediakan perusahaan yang membayar dividen tinggi',
        a16: 'Peningkatan dalam tempoh lima tahun melebihi 100%',
        a17: 'Pemilihan stok kuantitatif',
        a18:'3 Tahun',
        a19: '1 Tahun',
        a20: 'risiko rendah',
        a21: 'risiko sederhana',
        a22:'risiko sederhana tinggi',
        a23:'Berisiko tinggi',
        a24:'Keuntungan terkumpul',
        a25: 'potongan',
        a26:'Penebusan fleksibel',
        a27:'Syor pakar',
        a28:'Pendapatan tahunan',
        a29:'stabil',
        a30:'Berkat data besar',
        a31:'Tiada kerugian',
        a32:'Pertumbuhan mantap',
        a33:'Pilih yang terbaik'
    },
    fundPurchase:{
        b1:'Tencent Teng an',
        b2:'Jumlah beli',
        b3:'Peraturan dagangan',
        b4:'Jumlah yang anda boleh beli dana ialah:',
        b5:'Kadar beli',
        b6:'Sila masukkan kata laluan',
        b7:'Kata laluan ialah 6 digit',
        b8: 'Saya sedar tentang amaran risiko',
        b9: 'Pembelian berjaya',
        b10:'Sila masukkan jumlah',
        b11: 'Sila tandakan kotak untuk bersetuju',
        b12:'Beli',
        b13: 'Bermula dari 1 yuan'

    },
    user:{
        c1:'Pengurusan Kewangan',
        c2:'Pautan Pengurusan Kewangan Tencent',
        c3: 'Selamat datang ke perjalanan kekayaan',
        c4:'Aset Saya',
        c5: 'membekukan',
        c6:'Aset semasa',
        c7:'Aset bunyi',
        c8:'Aset Terperinci',
        c9:'Fleksibiliti jangka pendek',
        c10: 'Mencari kestabilan',
        c11: 'Kejar keuntungan',
        c12:'Perkhidmatan alat',
        c13:'Akaun',
        c14:'Baki',
        c15:'Dana',
        c16:'Maklumat lanjut',
        c17:'Keselamatan Akaun',
        c18:'Khidmat pelanggan saya',
        c19: 'Mengenai Pengurusan Kekayaan',
        c20:'Tarik diri',
        c21:'Tambah nilai'
    },
   
    accountChanges:{
        d1:'Tencent Pengurusan Kewangan',
        d2:'Rekod perubahan akaun'
    },
    funChanges:{
        e1:'Rekod transaksi dana',
        e2:'sedang berjalan',
        e3: 'Tamat tempoh',
        e4: 'Masa yang tinggal',
        e5:'天',
        e6:'Butiran pesanan',
        e7:'Status:',
        e8: 'sedang berjalan',
        e9:'Tamat',
        e10: 'Penyelesaian digantung',
        e11:'Tempoh',
        e12: 'Bilangan hari yang tinggal',
        e13:'Kuantiti pembelian',
        e14:'Pendapatan terkumpul',
        e15: 'Masa pembelian'
    },
    userInfo:{
        f1:'Maklumat peribadi',
        f2: 'Lelaki',
        f3: 'perempuan',
        f4: "Umur",
        f5:'Sila pilih negara',
        f6:'Nama bank',
        f7:'Nombor kad bank',
        f8: "Sila tetapkan kata laluan transaksi",
        f9:'Sahkan kata laluan transaksi',
        f10: 'Sila masukkan',
        f11: 'Sila masukkan semula',
        f12:'Serah',
        f13:''
    },
    fundDetails:{
        e1:'',
        e2: 'risiko rendah',
        e3: 'risiko sederhana',
        e4: 'berisiko sederhana hingga tinggi',
        e5: 'Berisiko tinggi',
        e6:'Pembelian minimum Yuan',
        e7: 'Tetapkan peningkatan',
        e8: 'Peningkatan pada tahun lalu',
        e9:'Peningkatan dalam tempoh tiga tahun yang lalu',
        e10:'beli',
        e11:'Prestasi Sejarah',
        e12:'Pindah ke RM1,000, keuntungan harian dalam 7 hari yang lepas adalah seperti berikut',
    },
    bankCard:{
        a1:'Maklumat kad bank',
        a2:'Pilih negara',
        a3:'Pilih bank',
        a4:'Nombor kad bank',
        a5:'Sila masukkan nombor kad bank anda',
        a6:'Pemegang Kad',
        a7: 'Sila masukkan nama pemegang kad',
        a8:'Lihat kad bank terikat',
        a9: 'mengikat',
        a10:'Sila masukkan kata laluan',
        a11: 'Berjaya mengikat',
        a12: 'Input salah',
        a13:'Kad saya',
        a14: 'Anda belum mengikat kad lagi',
        a15:'kemas kini Kad bank',
        a16: 'Kad keselamatan',
        a17: 'Menjadi kad selamat',
        a18: 'Kad yang digunakan untuk membeli produk kewangan buat kali pertama akan menjadi kad selamat',
        a19:'Alih keluar kad keselamatan',
        a20: 'Kad keselamatan terikat pada akaun kewangan, dan dana daripada bank hanya boleh dikeluarkan daripada kad keselamatan',
        a21: 'keselamatan terjamin',
        a22:'Walaupun telefon anda hilang, dana hanya boleh dikeluarkan ke kad keselamatan anda',
        a23:'Ganti kad keselamatan',
        a24: 'Selepas lulus semakan rasmi, kad keselamatan boleh diganti',
    },
    accountChanges:{
        a1:'Perubahan akaun',
        a2: 'jenis',
        a3:'Pilih jenis',
        a4:'Nombor pesanan',
        a5:'jumlah',
        a6:'Baki sebelum pembelian',
        a7:'Baki selepas pembelian',
        a8: 'masa',
        a9:'Nombor pesanan',
        a10: 'Tarik diri',
        a11: 'Bekukan',
        a12:"Yu'E Bao Pindahan",
        a13: 'Pengurusan kewangan pembelian',
        a14: 'potongan',
        a15:'Ganjaran log masuk harian',
        a16:'Alipay cas semula',
        a17: 'Pengurusan kewangan tamat tempoh',
        a18:'Baki + pindahan keluar',
        a19:'Nyahbeku',
        a20: 'Pengeluaran gagal'
       
    },
    setPwd:{
        a1:'Tetapkan kata laluan transaksi',
        a2:'Kata laluan transaksi',
        a3:'Sila masukkan kata laluan transaksi 6 digit',
        a4:'Sahkan kata laluan transaksi',
        a5:'Sila masukkan kata laluan transaksi sekali lagi',
        a6: 'Sila masukkan 6 kata laluan transaksi tambahan',
        a7:'Simpan',
        a8:'Set berjaya',
        a9: 'Jika terdapat keputusan dalam input anda, sila masukkan semula',
    },
    Withdraw:{
        a2: 'Tarik diri',
        a3:'Jumlah pengeluaran',
        a4:'Kata laluan urus niaga',
        a5: 'Masukkan kata laluan transaksi',
        a6: 'Lihat rekod pengeluaran',
        a7:'Tarik diri',
        a8: 'Mengeluarkan wang',
        a9:'Jumlah wang tunai yang ada'
    },
    WithdrawRecord:{
        e1:'Rekod transaksi dana',
        e2:'sedang berjalan',
        e3:'Rekod',
        e4: 'Pengeluaran gagal',
        e5:'Dalam semakan',
        e6:'Butiran pesanan',
        e7:'Status:',
        e8:'Dalam semakan',
        e9:'Tamat',
        e10: 'Penyelesaian digantung',
        e11:'Alamat pengeluaran',
        e12:'Nama bank',
        e13:'Pemegang Kad',
        e14:'mata wang',
        e15: 'Masa penciptaan',
        e16: 'Pengeluaran berjaya',
        e17: 'Masa kemas kini',
        e18:'Jumlah'
    },
    aboutas:{
        a1: 'Pengenalan kepada Pengurusan Kekayaan',
        a2:'Wealth Management ialah platform Shanghai Tengfu Financial Information Services Co., Ltd., anak syarikat Tencent, yang bekerjasama dengan beberapa institusi kewangan untuk menyediakan pengguna dengan perkhidmatan kewangan yang pelbagai. Nombor pendaftaran/lesen laman webnya: No. ICP Shanghai 2021008169-1. ',
        a3: 'Di Platform Pengurusan Kekayaan, institusi kewangan, sebagai penyedia produk kewangan, bertanggungjawab untuk reka bentuk struktur dan operasi aset produk kewangan, dan menyediakan pengguna dengan pembukaan akaun, pendaftaran akaun, pembelian produk, pengagihan pendapatan, pengeluaran produk, berkongsi perkhidmatan pertanyaan, dsb., dan pada masa yang sama mematuhi undang-undang dan peraturan yang berkaitan, mengurus dan menggunakan aset mengikut prinsip niat baik, berhemat dan ketekunan, serta melindungi hak dan kepentingan sah pengguna. ',
        a4:'Sebagai platform perkhidmatan, Wealth Management Connect menyediakan pengguna dengan akses kepada perkhidmatan tambahan seperti pertanyaan maklumat produk kewangan dan pesanan transaksi dengan menghubungkan dengan sistem berbilang institusi kewangan, dan membantu pengguna dalam menyelesaikan pembukaan akaun, pembelian dan penjualan di kewangan. institusi. Perkhidmatan seperti mendapatkan semula dan pertanyaan maklumat. Apabila pengguna membeli produk kewangan yang berkaitan melalui Pengurusan Kekayaan, mereka perlu memahami dengan teliti risiko pelaburan yang berkaitan dengan produk kewangan, mempertimbangkan sepenuhnya toleransi risiko mereka sendiri dan membuat keputusan pelaburan bebas berdasarkan prinsip "caveat emptor". '
    },
    recharge: {
        r1: 'Jaminan Keselamatan Dana',
        r2: 'Pampasan dana sehingga sejuta',
        r3: 'Sejurus masuk akaun',
        r4: 'Tiada caj urusan',
        r5: 'Bila-bila masatunai',
        r6: 'Jumlah Topup',
        r7: 'Topup Sekarang',
        r8: 'Kenapa Pilih Kami',
        r9: 'Pelaburan Pelbagai',
        r10: 'Pulangan yang Lebih Tinggi',
        r11: 'Pelbagai Mentor',
        r12: 'Sifar Kerugian',
    }


}
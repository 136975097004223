export default {
    common: {
        title: 'Wealth Management',
        symbol: '$',
        currency: 'dollars',
        login: 'login',
        lang1: 'Just now',
        lang2: ' minutes ago',
        lang3: ' hours ago',
        lang4: 'Yesterday',
        lang5: 'My Assets',
        lang6: 'Yesterday\'s Earnings',
        lang7: 'View More',
        lang8: "Yu'E Bao",
        lang9: 'Fund',
        lang10: 'Favorites',
        lang11: 'Highest 7-Day Annualized Return',
        lang12: 'Low Risk',
        lang13: 'Medium Low Risk',
        lang14: 'Medium High Risk',
        lang15: 'High Risk',
        lang16: ' 3 Years',
        lang17: '1 Year',
        lang18: 'Accumulated Earnings',
        lang19: 'Holding',
        lang20: 'Cancel',
        lang21: 'No data available at the moment',
        lang22:'confirm',
        lang23:'cancel',
        lang24:'No more',
        lang25: 'Language',
        lang26:'No card bound',
        lang27:'Order details',
        lang28:'Scan the QR code and add me as a friend',
        lang29:'Received successfully'
    },
    shareCard: {
        lang1: 'Prompt',
        lang2: 'Confirm',
        lang3: 'Adding a "Share" card will remove all existing ones. Are you sure you want to add it?',
        lang4: 'Adding an image will remove the "Share" card. Are you sure you want to add it?',
        lang5: 'Share',
        lang6: 'Done',
    },
    
    tabbar: {
        lang1: 'Today',
        lang2: 'Funds',
        lang3: 'Mine',
    },
    
    login: {
        lang1: 'Create a New Account',
        lang2: 'Already Have an Account',
        lang3: 'Contact Customer Service',
        lang4: 'Unauthorized login, please reauthorize'
    },
    
    home: {
        lang1: 'New User Specials',
        lang2: 'No Historical Loss, Instant Withdrawal',
        lang3: 'Try Buying One',
        lang4: 'Limited Assets Sale Reminder',
        lang5: 'Long-press to Follow Finance Account',
        lang6: 'Tencent Official',
        lang7: 'Over 400 Million Investment Users, Managing Assets Over 2 Trillion',
        lang8: 'Massive Funds',
        lang9: 'Nearly 1000 Funds to Choose from, Starting from 10% Off',
        lang10: 'Security Guarantee',
        lang11: 'Strict Risk Control, No Delay in Redemption, Zero Fund Freeze',
        lang12: 'Revenue details'
    },
    
    balance: {
        lang1: 'Historical Daily Positive Earnings, Fastest Withdrawal',
        lang2: 'View Details',
        lang3: 'Total Amount',
        lang4: 'Withdraw',
        lang5: 'Deposit',
        lang6: 'Discussion Area',
        lang7: "4 Major Features of Yu'E Bao",
        lang8: 'Enjoy Earnings',
        lang9: 'Versatile Use',
        lang10: 'Withdraw Anytime',
        lang11: 'Secured',
        lang12: 'Transaction Details',
        lang13: 'Earnings Details',
        lang14: 'Withdrawal Amount',
        lang15: 'Can be Withdrawn',
        lang16: 'Please Enter Amount',
        lang17: 'Operation Successful',
        lang18: 'Deposit Amount',
        lang19: 'I have read and agreed to the risk warning and service agreement',
        lang20: 'Please check the service agreement',
        lang21: 'Can transfer in',
    },
    comment: {
        lang1: "Yu'E Bao Discussion Area",
        lang2: 'Product Details',
        lang3: 'Newest',
        lang4: 'Essential',
        lang5: 'Posted a Post',
        lang6: 'Share Your Opinion',
        lang7: 'Post',
        lang8: 'Share Your Thoughts',
        lang9: 'Image',
        lang10: 'Emoji',
        lang11: 'Content cannot be empty',
        lang12: 'Post Successful',
    },
    fund:{
        a1:'Finance Management',
        a2: 'Profit',
        a3:'Total ',
        a4:'Yesterdays ',
        a5:'Total income',
        a6:'Long Bull Fund',
        a7:'See more',
        a8: 'performance outperform the market',
        a9: 'growth stocks',
        a10: 'Approved by over 310,000 users',
        a11:'Super market for eight consecutive years',
        a12:'AI-assisted quantification optimization',
        a13:'Multiple precise layouts',
        a14:'Focus on the style of small and medium caps',
        a15: 'Lay out high dividend-paying enterprises',
        a16:'The increase in five years exceeded 100%',
        a17:'Quantitative stock selection',
        a18:'3 Years',
        a19:' 1 Years',
        a20:'low risk',
        a21:'medium risk',
        a22:'medium-high risk',
        a23:'High risk',
        a24:'Cumulative profit ',
        a25:'cutoff',
        a26:'Flexible redemption',
        a27:'Expert recommendation',
        a28:'Annual income',
        a29:'Robust level',
        a30:'Big data blessing',
        a31:'No losses',
        a32:'Steady growth',
        a33:'Choose the best'
    },
    fundPurchase:{
        b1:'Tencent Teng an',
        b2:'Buy amount',
        b3:'Trading rules',
        b4:'The amount you can purchase funds is:',
        b5:'Buy rate',
        b6:'Please enter  password',
        b7:'Password is 6 digits',
        b8: 'I am aware of the risk warning ',
        b9:'Purchase successful',
        b10:'Please enter the amount',
        b11:'Please check the box to agree',
        b12:'Buy',
        b13:'Starting from 1 yuan'

    },
    user:{
        c1:'Finance Management',
        c2:'Tencent Financial Management Link',
        c3:'Welcome to the journey of wealth',
        c4:'My Assets',
        c5:'freeze',
        c6:'Current assets',
        c7:'Sound assets',
        c8:'Advanced Assets',
        c9:'Short-term flexibility',
        c10:'Seeking stable',
        c11:'Pursue profits',
        c12:'Tool service',
        c13:'Account ',
        c14:'Balance ',
        c15:'Fund ',
        c16:'More information',
        c17:'Account Security',
        c18:'My customer service',
        c19:'About Wealth Management',
        c20:'Withdraw',
        c21:'Recharge'

    },
   
    accountChanges:{
        d1:'Tencent Financial Management',
        d2:'Account change record'
    },
    funChanges:{
        e1:'Fund transaction record',
        e2:'in progress',
        e3:'Expired',
        e4:'Remaining time',
        e5:'天',
        e6:'Order details',
        e7:'Status:',
        e8:'in progress',
        e9:'Ended',
        e10:'Settlement suspended',
        e11:'Period',
        e12:'Number of days remaining',
        e13:'Purchase quantity',
        e14:'Cumulative income',
        e15:'Purchase time'
    },
    userInfo:{
        f1:'Personal information',
        f2:'Male',
        f3:'female',
        f4:"Age",
        f5:'Please select a country',
        f6:'Bank name',
        f7:'Bank card number',
        f8: "Please set a transaction password",
        f9:'Confirm transaction password',
        f10:'Please enter',
        f11:'Please enter again',
        f12:'Submit',
        f13:''
    },
    fundDetails:{
        e1:'',
        e2:'low risk',
        e3:'medium risk',
        e4:'medium to high risk',
        e5:'High risk',
        e6:'Minimum purchase of Yuan',
        e7:'Establish increase',
        e8:'Increase in the past year',
        e9:'Increase in the past three years',
        e10:'buy',
        e11:'Historical Performance',
        e12:'Transfer to RM1,000, daily returns for the past 7 days are as follows',
    },
    bankCard:{
        a1:'Bank card information',
        a2:'Select country',
        a3:'Select bank',
        a4:'Bank card number',
        a5:'Please enter your bank card number',
        a6:'Cardholder',
        a7: 'Please enter the cardholders name',
        a8:'View bound bank cards',
        a9:'bind',
        a10:'Please enter password',
        a11:'Binding successful',
        a12:'Incorrect input',
        a13:'My card',
        a14:'You havent bound the card yet',
        a15:'update bankCard',
        a16: 'Security card',
        a17:'Become a safe card',
        a18: 'The card used to purchase financial products for the first time will become a secure card',
        a19:'Remove security card',
        a20: 'The security card is bound to the financial account, and funds from the bank can only be withdrawn from the security card',
        a21:'safety guaranteed',
        a22:'Even if your phone is lost, funds can only be withdrawn to your security card',
        a23:'Replace security card',
        a24: 'After passing the official review, the security card can be replaced',
    },
    accountChanges:{
        a1:'Account change',
        a2:'type',
        a3:'Select type',
        a4:'Order number',
        a5:'amount',
        a6:'Balance before purchase',
        a7:'Balance after purchase',
        a8:'time',
        a9:'Order number',
        a10:'Withdraw',
        a11:'Freeze',
        a12:"Yu'E Bao Transfer",
        a13:'Purchase financial management',
        a14:'deduction',
        a15:'Daily login reward',
        a16:'Alipay recharge',
        a17:'Financial management expires',
        a18:'Balance + transfer out',
        a19:'Unfreeze',
        a20:'Withdrawal failed'
    },
    setPwd:{
    a1:'Set transaction password',
        a2:'Transaction password',
        a3:'Please enter the 6-digit transaction password',
        a4:'Confirm transaction password',
        a5:'Please enter the transaction password again',
        a6: 'Please enter 6 additional transaction password',
        a7:'Save',
        a8:'Set successfully',
        a9: 'If there are results in your input, please re-enter',
    },
    Withdraw:{
        a2:'Withdraw',
        a3:'Withdrawal amount',
        a4:'Transaction password',
        a5:'Enter transaction password',
        a6:'View withdrawal records',
        a7:'Withdraw',
        a8:'Withdrawing money',
        a9:'Amount of cash available'
    },
    WithdrawRecord:{
        e1:'Fund transaction record',
        e2:'in progress',
        e3:'Record',
        e4:'Withdrawal failed',
        e5:'Under review',
        e6:'Order details',
        e7:'Status:',
        e8:'Under review',
        e9:'Ended',
        e10:'Settlement suspended',
        e11:'Withdrawal address',
        e12:'Bank name',
        e13:'Cardholder',
        e14:'currency',
        e15:'Creation time',
        e16:'Withdrawal successful',
        e17:'Update time',
        e18:'Amount'
    },
    aboutas:{
        a1:'Introduction to Wealth Management',
        a2:'Wealth Management is a platform of Shanghai Tengfu Financial Information Services Co., Ltd., a subsidiary of Tencent, which cooperates with a number of financial institutions to provide users with diversified financial services. Its website registration/license number: Shanghai ICP No. 2021008169-1. ',
        a3: 'On the Wealth Management Platform, financial institutions, as providers of financial products, are responsible for the structural design and asset operation of financial products, and provide users with account opening, account registration, product purchase, income distribution, product withdrawal, share inquiry, etc. services, and at the same time strictly comply with relevant laws and regulations, manage and use assets in accordance with the principles of good faith, prudence and diligence, and protect the legitimate rights and interests of users. ',
        a4:'As a service platform, Wealth Management Connect provides users with access to auxiliary services such as financial product information inquiry and transaction ordering by connecting with the systems of multiple financial institutions, and assists users in completing account opening, buying and selling at financial institutions. Services such as retrieval and information inquiry. When users purchase relevant financial products through Wealth Management, they need to carefully understand the investment risks associated with financial products, fully consider their own risk tolerance, and make independent investment decisions based on the principle of "caveat emptor". '
    },
    recharge: {
        r1: 'Funds Security Guarantee',
        r2: 'Up to a million fund compensation',
        r3: 'Real-time crediting',
        r4: 'No handling fees',
        r5: 'Withdraw anytime',
        r6: 'Recharge Amount',
        r7: 'Recharge Now',
        r8: 'Why Choose Us',
        r9: 'Diverse Investments',
        r10: 'Higher Returns',
        r11: 'Abundant Mentors',
        r12: 'Zero Loss',
    }


}
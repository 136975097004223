export default {
    common: {
        title: 'ファイナンスプラス',
        symbol: '¥',
        currency: '円',
        login: 'ログイン',
        lang1: 'たった今',
        lang2: '分前',
        lang3: '時間前',
        lang4: '昨日',
        lang5: '私の資産',
        lang6: '昨日の収益',
        lang7: 'さらに表示',
        lang8: "Yu'E Bao",
        lang9: 'ファンド',
        lang10: 'カスタム',
        lang11: '最高7日間の年率',
        lang12: '低リスク',
        lang13: '中リスク',
        lang14: '中高リスク',
        lang15: '高リスク',
        lang16: '直近3年の変動率',
        lang17: '直近1年の変動率',
        lang18: '累積収益',
        lang19: '保有中',
        lang20: 'キャンセル',
        lang21: '現在データはありません',
        lang22:'確認',
        lang23:'キャンセル',
        lang24:'もうだめ',
        lang25: '言語',
        lang26:'カードがバインドされていません',
        lang27:'注文詳細',
        lang28:'QRコードをスキャンして友達に追加してください',
        lang29:'正常に受信しました'
        
      },
      shareCard: {
        lang1: '注意',
        lang2: '確認',
        lang3: '「晒一晒」カードを追加すると、すべて削除されます。追加しますか？',
        lang4: '画像を追加すると、「晒一晒」カードが削除されます。追加しますか？',
        lang5: '晒一晒',
        lang6: '完了',
      },
      tabbar: {
        lang1: '今日',
        lang2: 'ファンド',
        lang3: '私の',
      },
      login: {
        lang1: '新しいアカウントを作成',
        lang2: '既にアカウントをお持ちの場合',
        lang3: 'カスタマーサービスに連絡',
        lang4: '認証されていないログインです。再度認証してください'
      },
      home: {
        lang1: '新規ユーザー特選',
        lang2: '歴史的な損失なし、即時振り込みで転出',
        lang3: '一つ購入して試してみる',
        lang4: '希少資産の販売が開始されました。お知らせします',
        lang5: 'ファイナンスプラスアカウントを長押ししてフォロー',
        lang6: 'Tencent 公式',
        lang7: '投資ユーザーが4億人を超え、資産は2兆円を超えています',
        lang8: '膨大な数のファンド',
        lang9: '1000以上のファンドから選ぶ、手数料1割引から',
        lang10: '安全保障',
        lang11: 'リスクを厳しくコントロールし、償還は0延期、資金は0検索されていません',
        lang12: '収益の詳細'
      },
      balance: {
        lang1: '歴史的に毎日プラスの収益、転出は最速で即座に振り込まれます',
        lang2: '詳細を見る',
        lang3: '総額',
        lang4: '転出',
        lang5: '転入',
        lang6: 'ディスカッションエリア',
        lang7: '余額宝の4つの特徴',
        lang8: '収益を楽しむ',
        lang9: '用途が多岐',
        lang10: 'いつでも引き出し可能',
        lang11: '保証があります',
        lang12: '取引履歴',
        lang13: '収益履歴',
        lang14: '転出金額',
        lang15: '転出可能',
        lang16: '金額を入力してください',
        lang17: '操作が成功しました',
        lang18: '転入金額',
        lang19: 'リスクについての警告を理解し、サービス契約に同意しています',
        lang20: 'サービス契約にチェックを入れてください',
        lang21: '振り込むことができます',
    },
    comment: {
        lang1: '余額宝ディスカッションエリア',
        lang2: '製品の詳細',
        lang3: '最新',
        lang4: 'エッセンス',
        lang5: '投稿しました',
        lang6: '意見を述べる',
        lang7: '発表する',
        lang8: 'あなたの意見を述べる',
        lang9: '画像',
        lang10: '絵文字',
        lang11: '内容が空です',
        lang12: '投稿が成功しました',
    },
    fund: {
        a1: 'ファイナンスプラス',
        a2: '収益',
        a3: '総購入ファンド',
        a4: '昨日の収益',
        a5: '総収益',
        a6: 'ロングブルファンド',
        a7: 'もっと見る',
        a8: '様々な業界をバランスよく配置し、成績は大盤を連続して上回っています',
        a9: 'アクティブに成長する株式を選り抜く',
        a10: '31万人以上のユーザーに認められています',
        a11: '大盤を8年連続で上回る',
        a12: 'AIサポートの量的選択',
        a13: '何度も正確な配置',
        a14: '中小型株スタイルに注力',
        a15: '高配当企業を配置',
        a16: '5年間のリターンが100%を超えています',
        a17: '量的な株の選択',
        a18: '直近3年の変動率',
        a19: '直近1年の変動率',
        a20: '低リスク',
        a21: '中リスク',
        a22: '中高リスク',
        a23: '高リスク',
        a24: 'ユーザーへの累積利益',
        a25: '現在',
        a26: '柔軟な申し込みと償還',
        a27: 'エキスパートのおすすめ',
        a28: '年間収益の追求',
        a29: '安定した水準',
        a30: 'ビッグデータのサポート',
        a31:'損はない',
        a32:'着実な成長',
        a33:'最高のものを選ぶ'
      },
      fundPurchase: {
        b1: 'Tencent Teng An',
        b2: '購入金額',
        b3: '取引規則',
        b4: '購入可能なファンドの限度額は次のとおりです：',
        b5: '購入手数料',
        b6: '支払いパスワードを入力してください',
        b7: 'パスワードは6桁の数字です',
        b8: 'リスクの警告を理解し、サービス契約に同意しています',
        b9: '購入が成功しました',
        b10: '金額を入力してください',
        b11: '同意にチェックを入れてください',
        b12: '購入',
      },
      user: {
        c1: 'ファイナンスプラス',
        c2: 'Tencent ファイナンスプラス',
        c3: '財富の旅を開始する歓迎',
        c4: '私の資産',
        c5: '昨日',
        c6: '流動資産',
        c7: '安定した資産',
        c8: 'ステップアップ資産',
        c9: '短期的に柔軟',
        c10: '安定した価値のあるものを求める',
        c11: '利益を求める',
        c12: 'ツールとサービス',
        c13: 'アカウント',
        c14: '残高履歴',
        c15: 'ファンドの記録',
        c16: '詳細情報',
        c17: 'アカウントの安全性',
        c18: '私のカスタマーサービス',
        c19: 'Tencent ファイナンスプラスについて',
        c20:'撤退する',
        c21:'お金を節約する'
      },
      accountChanges: {
        d1: 'Tencent ファイナンスプラス',
        d2: 'アカウント変更履歴',
      },
      funChanges: {
        e1: 'ファンド取引履歴',
        e2: '進行中',
        e3: '期限切れ',
        e4: '残り時間',
        e5: '日',
        e6: '注文の詳細',
        e7: 'ステータス：',
        e8: '進行中',
        e9: '終了しました',
        e10: '精算が一時停止',
        e11: 'サイクル',
        e12: '残りの日数',
        e13: '購入数量',
        e14: '累計収益',
        e15: '購入時間',
      },
      userInfo: {
        f1: '個人情報',
        f2: '男性',
        f3: '女性',
        f4: '年齢',
        f5: '国を選択してください',
        f6: '銀行名',
        f7: '銀行カード番号',
        f8: '取引パスワードを設定してください',
        f9: '取引パスワードを確認',
        f10: '6桁の取引パスワードを入力してください',
        f11: 'もう一度入力してください',
        f12: '提出',
        f13: '',
    },
    fundDetails: {
        e1: 'Tencentファイナンスプラス',
        e2: '低リスク',
        e3: '中リスク',
        e4: '中高リスク',
        e5: '高リスク',
        e6: '元から購入',
        e7: '設立時の値上がり率',
        e8: '過去1年の値上がり率',
        e9: '過去3年の値上がり率',
        e10: '今すぐ購入',
        e11:'歴史的な実績',
        e12:'1万円を転送すると、過去7日間の毎日の収益は次のとおりです',
    },
    bankCard: {
        a1: '銀行カード情報',
        a2: '国を選択',
        a3: '銀行を選択',
        a4: '銀行カード番号',
        a5: '銀行カード番号を入力してください',
        a6: 'カード名義人',
        a7: 'カード名義人の名前を入力してください',
        a8: 'すでにバインドされた銀行カードを確認',
        a9: 'バインド',
        a10: '支払いパスワードを入力してください',
        a11: 'バインド成功',
        a12: '入力エラー',
        a13: '私のカード',
        a14: 'まだカードをバインドしていません',
    },
    accountChanges: {
        a1: 'アカウント変更',
        a2: 'タイプ',
        a3: 'タイプを選択',
        a4: '注文番号',
        a5: '金額',
        a6: '購入前の残高',
        a7: '購入後の残高',
        a8: '時間',
        a9: '注文番号',
        a10:'撤退',
        a11:'フリーズ',
        a12:'残高+振込',
        a13:'購買財務管理',
        a14:'控除',
        a15:'毎日のログイン報酬',
        a16:'Alipay リチャージ',
        a17:'財務管理の期限が切れます',
        a18:'残高+送金',
        a19:'解凍',
        a20:'出金に失敗しました'
    },
    setPwd: {
        a1: '取引パスワードを設定',
        a2: '取引パスワード',
        a3: '6桁の取引パスワードを入力してください',
        a4: '取引パスワードを確認',
        a5: 'もう一度取引パスワードを入力してください',
        a6: '6桁の取引パスワードを入力してください',
        a7: '保存',
        a8: '設定が成功しました',
        a9: '入力エラー。もう一度入力してください',
    },
    recharge: {
      r1: '資金の安全保障',
      r2: '最大100万の資金補償',
      r3: 'リアルタイム入金',
      r4: '手数料無料',
      r5: 'いつでも引き出し可能',
      r6: '入金金額',
      r7: '今すぐ入金',
      r8: 'なぜ私たちを選ぶのか',
      r9: '多様な投資',
      r10: 'より高いリターン',
      r11: '豊富なメンター',
      r12: 'ゼロ損失',
  }



}
import Vue from 'vue'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
//中文
import zh from './zh.js'
//英语
import en from './en.js'
// 日文
import ja from './ja.js'
//马来
import ms from './ma.js'
//印尼
import id from './ind.js'
//越南
import vi from './vi.js'

const messages = {
	en,
    ja,
	zh,
	ms,
	id,
	vi
	
}

let i18nConfig = {
	locale: sessionStorage.getItem('UNI_LOCALE') ?  sessionStorage.getItem('UNI_LOCALE') : 'ms',
	messages
}
const i18n = new VueI18n(i18nConfig)
export default i18n
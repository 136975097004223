import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { Toast } from 'vant'
import { getBalancePlusData,getUserInfo,getWechatCode,getDict } from '../api'

const store = new Vuex.Store({
	state: {
		balancePlusInfo: {
			balance: '0.00',
			balance_plus: '0.00',
			yesterday_profit: '0.00',
			total_profit: '0.00',
			annualized: '0.00%'
		},
		userInfo: {
			balance: '0.00',
			yesterdayIncome: '0.00',
			freeze: '',
			audit:''
		},
		wechatCode: null,
		customeUrl: null
	},
	mutations: {
		setBalancePlusInfo(state, data) {
			state.balancePlusInfo = data;
		},
		setUserInfo(state, data) {
			state.userInfo = data;
		},
		setWechatCode(state, data) {
			state.wechatCode = data;
		},
		setCustomeUrl(state, data) {
			state.customeUrl = data;
		},
	},
	actions: {
		async fetchBalancePlusData({ commit }) {
			try {
				const res = await getBalancePlusData();
				if (res.code === 0) {
					commit('setBalancePlusInfo', res.data.data);
				} else {
					Toast(res.msg);
				}
			} catch (error) {
				console.error(error);
			}
		},
		async fetchUserInfo({ commit }) {
			try {
				const res = await getUserInfo();
				if (res.code === 0) {
					commit('setUserInfo', res.data);
				} else {
					Toast(res.msg);
				}
			} catch (error) {
				console.error(error);
			}
		},
		async fetchWechatCode({ commit }) {
			try {
				const res = await getWechatCode({
					title: '微信客服'
				});
				if (res.code === 0) {
					commit('setWechatCode', res.data.data.image);
				} else {
					Toast(res.msg);
				}
			} catch (error) {
				console.error(error);
			}
		},
		async fetchCustomeUrl({ commit }) {
			try {
				let data = {
					k: 'kefu'
				}
				let res = await getDict(data)
				if (res.code === 0) {
					commit('setCustomeUrl', res.data);
				} else {
					Toast(res.msg)
				}
			} catch (error) {
				console.error(error);
			}
            
        }
	},
	getters: {
		
	},
})
export default store